import Vue from 'vue';
import App from './App.vue';
import router from './router';

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import '@/assets/scss/index.scss';

Vue.config.productionTip = false;

Vue.use(ElementUI);

Vue.prototype.msgSuccess = function(msg) {
  this.$message({
    message: msg,
    type: 'success',
    duration: 2000,
    center: true,
  });
};

Vue.prototype.msgError = function(msg) {
  this.$message({
    message: msg,
    type: 'error',
    duration: 2000,
    center: true,
  });
};

new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app');
