import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: '/login',
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login.vue'),
  },
  {
    path: '/console',
    name: 'layout',
    redirect: '/console/car/sale',
    component: () => import('../layout/console/index.vue'),
    children: [
      {
        path: 'car/sale',
        component: () => import('../views/console/car/sale.vue'),
        meta: [{ name: '车辆管理' }, { name: '金融公司管理' }],
      },
      {
        path: 'car/car',
        component: () => import('../views/console/car/car.vue'),
        meta: [{ name: '车辆管理' }, { name: '车辆列表' }],
      },
      {
        path: 'bid',
        component: () => import('../views/console/bid/bid.vue'),
        meta: [{ name: '用户投标' }, { name: '投标列表' }],
      },
      {
        path: 'user',
        component: () => import('../views/console/user/user.vue'),
        meta: [{ name: '用户管理' }, { name: '用户列表' }],
      },
      {
        path: 'finance/record',
        component: () => import('../views/console/finance/record.vue'),
        meta: [{ name: '财务管理' }, { name: '收支明细' }],
      },
      {
        path: 'finance/refund',
        component: () => import('../views/console/finance/refund.vue'),
        meta: [{ name: '财务管理' }, { name: '退保证金' }],
      },
      {
        path: 'banner',
        component: () => import('../views/console/banner/banner.vue'),
        meta: [{ name: 'banner管理' }, { name: 'banner列表' }],
      },
      {
        path: 'sys/admin',
        component: () => import('../views/console/sys/admin.vue'),
        meta: [{ name: '系统管理' }, { name: '管理员列表' }],
      },
      {
        path: 'sys/log',
        component: () => import('../views/console/sys/log.vue'),
        meta: [{ name: '系统管理' }, { name: '日志列表' }],
      },
    ],
  },
];

const router = new VueRouter({
  routes,
});

// 点击重复路由报错
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

export default router;
